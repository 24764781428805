body {
  font-family: 'Poppins', serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #ffe272 !important;
  color: #000000 !important;
}

.banner-slider .swiper-button-prev,
.swiper-button-next {
  color: white;
}
.banner-slider .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 50px;
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 50px;
  left: auto;
}

.season-pass .swiper-pagination {
  bottom: -6px;
}

.season-pass.season-pass-updated .swiper-pagination {
  bottom: -6px;
}

.season-pass.dandiya-season-pass .swiper-pagination {
  bottom: 15px;
}
.season-pass .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}
.season-pass.season-pass-white
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #cb2027;
}
.season-pass.season-pass-black
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #cb2027;
}
.season-pass.season-pass-white .swiper-pagination .swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}
.season-pass.season-pass-black .swiper-pagination .swiper-pagination-bullet {
  background-color: #dcdcdc;
  opacity: 1;
}

.bullet-point ul {
  list-style-type: disc;
  margin-left: 20px;
}

.number-point ol {
  margin-left: 20px;
  list-style-type: decimal;
}

.splite-share-button {
  background: linear-gradient(
    180deg,
    rgba(238, 62, 69, 0.95) 40%,
    rgba(249, 204, 129, 0.85) 95.5%
  );
}

.search-border-liner-gradient {
  position: relative;
  background: white;
  border-radius: 50px;
}

.search-border-liner-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 50px;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.menu-text-liner-gradient {
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.btn-liner-gradient {
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
}

.select-location-border-liner-gradient {
  position: relative;
  background: white;
  border-radius: 8px;
}

.select-location-border-liner-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 8px;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.socail-border-liner-gradient {
  position: relative;
  background: white;
  border-radius: 48px;
}

.socail-border-liner-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 48px;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.detect-my-location-border-liner-gradient {
  position: relative;
  background: white;
  border-radius: 4px;
}

.detect-my-location-border-liner-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.facebook-liner-gareint-border {
  position: relative;
  background: white;
  border-radius: 6px;
}

.facebook-liner-gareint-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 6px;
  background: var(
    --button-gradient,
    linear-gradient(
      96.63deg,
      #f0ba0e -28.78%,
      #cb2129 28.58%,
      #cb2129 64.86%,
      #f0ba0e 137.34%
    )
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}


.pass-location {
  position: relative;
  background: white;
  border-radius: 4px;
}

.pass-location::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  background: var(
    --button-gradient,
    linear-gradient(
      96.63deg,
      #f0ba0e -28.78%,
      #cb2129 28.58%,
      #cb2129 64.86%,
      #f0ba0e 137.34%
    )
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}


.feedback-title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-border-liner-gradient {
  border: none;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  border-image-slice: 1;
}
.pass-border-liner-gradient {
  border: none;
  border-top: 1px solid;
  border-image-source: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  border-image-slice: 1;
}

.footer-border-top-liner-gradient {
  border: none;
  border-top: 1px solid;
  border-image-source: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  border-image-slice: 1;
}
.banner-slider .swiper-pagination {
  bottom: 28px;
}
.banner-slider .swiper-pagination-bullet {
  width: 16px;
  height: 8px;
  border-radius: 4px;
  background: #e2e2e2;
  opacity: 1;
}
.banner-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f0ba0e;
  width: 24px;
}

.event-category-slider
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #cb2129;
  width: 24px;
}

.event-details-slider .swiper-slide {
  width: initial;
}
.event-details-slider .swiper-pagination {
  bottom: 7px;
}
.gallary-slider .swiper-pagination {
  bottom: 5px;
}
.gallary-slider .swiper-pagination-bullet {
  width: 16px;
  height: 8px;
  border-radius: 4px;
  background: #e2e2e2;
  opacity: 1;
}
.gallary-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #cb2129;
  width: 24px;
}

.blog-slider.gallary-slider .swiper-pagination {
  bottom: -5px;
}

.pass-ticket-slider.gallary-slider .swiper-pagination {
  bottom: -4px;
}
.select2-selection .css-djhi0x-control {
  background-color: white;
  padding: 8px 8px 8px 16px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
.select2-selection .css-1qj951v-control {
  background-color: white;
  padding: 8px 8px 8px 16px;
}
.select2-selection .css-hlgwow {
  padding: 0px 8px 0px 0px;
}

.switch-inner-shadow {
  box-shadow: 1px 1px 8px 0px #00000040 inset;
}

.coupon-bg {
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
}

.map-border-liner-gradient {
  position: relative;
  background: white;
  border-radius: 4px;
}

.map-border-liner-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent;
  border-radius: 4px;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.pass-outside-border-liner-gradient {
  position: relative;
  background: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.pass-outside-border-liner-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.wave {
  position: relative;
  width: 100%;
  height: 20px; /* Adjust the height to match your wave */
}

.star-gradient-color {
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pass-text-gradient-color {
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.qty-border-liner-gradient {
  position: relative;
}

.qty-border-liner-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.text-tittle-liner-gradient-color {
  background: linear-gradient(
    96.63deg,
    #f0ba0e -28.78%,
    #cb2129 28.58%,
    #cb2129 64.86%,
    #f0ba0e 137.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  outline: 1px solid #dcdcdc;
  border-radius: 40px;
}

.hover-container:hover img {
  fill: #cb2027;
}

.text-outline {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

.text-outline-main {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

.direction {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

@media (min-width: 320px) and (max-width: 991px) {
  .banner-slider .swiper-button-prev {
    display: none;
  }
  .banner-slider .swiper-button-next {
    display: none;
  }
  .select2-selection .css-djhi0x-control {
    padding: 8px 8px 8px 8px;
  }
  .select2-selection .css-1qj951v-control {
    padding: 8px 8px 8px 8px;
  }
  .css-1e3z3ba-placeholder {
    font-size: 13px;
  }
}
.react-player {
  width: auto !important;
  height: auto !important;
  border-radius: 0.75rem !important;
  overflow: hidden !important;
}
pre {
  font-family: 'Poppins', serif !important;
}

.answer-card {
  display: block;
  position: relative;
  border-radius: 8px;
  z-index: 0;
  padding: 32px 24px;
  overflow: hidden;
}

.answer-card:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -16px;
  right: 0;
  left: -20px;
  background: #f0ba0e;
  height: 302px;
  width: 450px;
  border-top-right-radius: 211px;
  border-top-left-radius: 211px;
  transform: scale(1);
  transform-origin: 10% 100%;
  transition: transform 1s ease-out;
}

.answer-card:hover:before {
  transform: scale(3);
}

.answer-card .event-hosting-title {
  transition: transform 0.5s ease-out;
}
.answer-card:hover .event-hosting-title {
  transform: translate(332px, 0px);
}
.answer-card .event-phone-image {
  transition: transform 3s ease-out;
  transform: scale(1);
  transform: rotate(6deg);
}

.answer-card .event-phone-image.new-phone {
  transform: rotate(-11deg);
}
.answer-card:hover .event-phone-image {
  opacity: 0;
  transform: scale(0.9);
}

.text-effect-custom-box {
  transition: transform 3s ease-out;
  opacity: 0;
}

.answer-card:hover .text-effect-custom-box {
  opacity: 1;
}

.answer-card.answer-card2:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0;
  left: -20px;
  background: #cb2129;
  height: 302px;
  width: 450px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 211px;
  border-bottom-left-radius: 211px;
  transform: scale(1);
  transform-origin: 80% 21%;
  transition: transform 1s ease-out;
}
.answer-card.answer-card2:hover:before {
  transform: scale(3);
}
.answer-card.answer-card2:hover .event-hosting-title {
  transform: translate(-450px, 0px);
}

.answer-card.answer-card3:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -16px;
  right: 0;
  left: -20px;
  background: #f0ba0e;
  height: 302px;
  width: 450px;
  border-top-right-radius: 211px;
  border-top-left-radius: 211px;
  transform: scale(1);
  transform-origin: 80% 100%;
  transition: transform 1s ease-out;
}
.answer-card.answer-card3:hover:before {
  transform: scale(3);
}
.answer-card.answer-card3:hover .event-hosting-title {
  transform: translate(-332px, 0px);
}

.steps-shadow {
  box-shadow: 0px 0px 4px 0px #0000001f;
}
.active-step-shadow {
  box-shadow: 0px 0px 4px 0px #0000001f;

  box-shadow: 0px 0px 4px 0px #00000040 inset;
}

@media (min-width: 320px) and (max-width: 767px) {
  .answer-card:before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: 0px;
    left: -16px;
    background: #f0ba0e;
    height: 149px;
    width: 216px;
    border-top-right-radius: 267px;
    border-top-left-radius: 276px;
    transform: scale(1);
    transform-origin: 13% 101%;
    transition: transform 1s ease-out;
  }
  .answer-card {
    padding: 32px 22px;
  }
  .answer-card.answer-card2:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0;
    left: -20px;
    background: #cb2129;
    height: 150px;
    width: 197px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 211px;
    border-bottom-left-radius: 211px;
    transform: scale(1);
    transform-origin: 80% 20%;
    transition: transform 1s ease-out;
  }
  .answer-card.answer-card3:before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: 0;
    left: -20px;
    background: #f0ba0e;
    height: 149px;
    width: 216px;
    border-top-right-radius: 211px;
    border-top-left-radius: 211px;
    transform: scale(1);
    transform-origin: 80% 100%;
    transition: transform 1s ease-out;
  }
}

.text-gradient-color {
  background: linear-gradient(
          96.63deg,
          #f0ba0e -28.78%,
          #cb2129 28.58%,
          #cb2129 64.86%,
          #f0ba0e 137.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-button-gradient-color {
 
  border-radius: 8px;
border: 1px solid linear-gradient(
          96.63deg,
          #f0ba0e -28.78%,
          #cb2129 28.58%,
          #cb2129 64.86%,
          #f0ba0e 137.34%
  );
background: #FFF;
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 203, 32, 39;

    --color-secondary: 255, 210, 84;

    --color-neutral: 33, 33, 33;

    --color-gainsboro: 220, 220, 220;

    --color-slatet: 169, 169, 161;
  }
  /* body {
    @apply font-body;
  } */
}
